<template>
  <div>
    <!-- Stats Card w/ Area Chart -->
    <b-row>
      <b-col lg="2" md="4" sm="6">
        <statistic-card-vertical
          icon="FolderPlusIcon"
          :statistic="this.active_slots"
          statistic-title="Active Slots"
          color="info"
        />
      </b-col>
      <b-col lg="3" sm="6">
        <statistic-card-with-area-chart
          icon="WindIcon"
          :statistic="this.requests"
          statistic-title="Requests"
          :chart-data="this.request_graph"
        />
      </b-col>
      <b-col lg="3" sm="6">
        <statistic-card-with-area-chart
          icon="LayersIcon"
          color="success"
          :statistic="this.ecpm"
          statistic-title="eCPM"
          :chart-data="this.ecpm_graph"
        />
      </b-col>
      <b-col lg="4" sm="6">
        <statistic-card-with-area-chart
          icon="CreditCardIcon"
          color="danger"
          :statistic="this.earning"
          statistic-title="Earning"
          :chart-data="this.earning_graph"
        />
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="4" sm="12">
        <b-card no-body>
          <b-card-header>
            <b-card-title>Fill Rate</b-card-title>
          </b-card-header>

          <b-card-body>

            <!-- apex chart -->
            <vue-apex-charts
              type="pie"
              height="325"
              class="mt-2 mb-1"
              :options="customersPie.chartOptions"
              :series="customersPie.series"
            />

            <!-- list group -->
            <div class="pt-25">

              <div class="d-flex justify-content-between mb-1">
                <div class="series-info">
                  <feather-icon
                    icon="CircleIcon"
                    size="16"
                    class="mr-50 text-primary"
                  />
                  <span class="font-weight-bolder"> Impressions </span>
                </div>
                <span> {{this.impressions}} </span>
              </div>

              <div class="d-flex justify-content-between mb-1">
                <div class="series-info">
                  <feather-icon
                    icon="CircleIcon"
                    size="16"
                    class="mr-50 text-warning"
                  />
                  <span class="font-weight-bolder"> Passback </span>
                </div>
                <span> {{this.passback}} </span>
              </div>

              <div class="d-flex justify-content-between mb-1">
                <div class="series-info">
                  <feather-icon
                    icon="CircleIcon"
                    size="16"
                    class="mr-50 text-danger"
                  />
                  <span class="font-weight-bolder"> Remnant </span>
                </div>
                <span> {{this.remnant}} </span>
              </div>


            </div>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col lg="8" sm="12">
        <b-card no-body>
          <b-card-header>
            <b-card-title>Impressions</b-card-title>
          </b-card-header>

          <b-card-body class="pb-0">
            <div class="d-flex justify-content-start mb-3">
              <div class="mr-2">
                <b-card-text class="mb-50">
                  Impressions
                </b-card-text>
                <h3 class="font-weight-bolder">
                  <span class="text-primary">{{ revenue.thisMonth }}</span>
                </h3>
              </div>
              <div>
                <b-card-text class="mb-50">
                  Pixels
                </b-card-text>
                <h3 class="font-weight-bolder">
                  <span>{{ revenue.lastMonth }}</span>
                </h3>
              </div>
              <div class="mr-2">
                <b-card-text class="mb-50">
                  Visible
                </b-card-text>
                <h3 class="font-weight-bolder">
                  <span class="text-primary">{{ revenue.thisMonth }}</span>
                </h3>
              </div>
            </div>

            <!-- apex chart -->
            <vue-apex-charts
              type="line"
              height="240"
              :options="revenueComparisonLine.chartOptions"
              :series="revenueComparisonLine.series"
            />
          </b-card-body>
        </b-card>

      </b-col>
    </b-row>

    <div>
      <!-- search input -->
      <div class="custom-search d-flex justify-content-end">
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">Search</label>
            <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
      </div>

      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
        :select-options="{
          enabled: true,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
      >
        <template slot="table-row" slot-scope="props" >
          <!-- Column: Name -->
          <span v-if="props.column.field === 'fullName'" class="text-nowrap" >
            <b-avatar :src="props.row.avatar" class="mx-1"/>
            <span class="text-nowrap">{{ props.row.fullName }}</span>
          </span>

          <!-- Column: Status -->
          <span v-else-if="props.column.field === 'status'">
            <b-badge :variant="statusVariant(props.row.status)">
              {{ props.row.status }}
            </b-badge>
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item>
                  <feather-icon
                    icon="Edit2Icon"
                    class="mr-50"
                  />
                  <span>Edit</span>
                </b-dropdown-item>
                <b-dropdown-item>
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                  />
                  <span>Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap ">
                Showing 1 to
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['3','5','10']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18"/>
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>
  </div>
</template>

<script>
import {BCard,BCardHeader,BCardTitle,BCardBody,BCardText, BRow, BCol, BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import useJwt from '@/auth/jwt/useJwt'
// charts
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

export default {
  components: {
    BRow,
    BCol,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    // charts
    StatisticCardVertical,
    StatisticCardWithAreaChart,
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardText,
    BCardTitle,
    BCardBody,
  },
  data() {
    return {
      pageLength: 3,
      dir: false,
      columns: [
        {
          label: 'Name',
          field: 'slot_name',
        },
        {
          label: 'Inventories',
          field: 'inventory_name',
        },
        {
          label: 'Request',
          field: 'requests',
        },
        {
          label: 'Impression',
          field: 'wins',
        },
        {
          label: 'Pixels',
          field: 'pixels',
        },
        {
          label: 'Visible',
          field: 'visible',
        },
        {
          label: 'Clicks',
          field: 'clicks',
        },
        {
          label: 'Earning',
          field: 'earning',
        },
        {
          label: 'Action',
          field: 'action',
        }
      ],
      rows: [],
      searchTerm: '',
      status: [
        {
          1: 'Current',
          2: 'Professional',
          3: 'Rejected',
          4: 'Resigned',
          5: 'Applied',
        },
        {
          1: 'light-primary',
          2: 'light-success',
          3: 'light-danger',
          4: 'light-warning',
          5: 'light-info',
        }
      ],
      // Area charts
      active_slots:0,
      requests:0,
      ecpm:0,
      earning:0,
      impressions:0,
      passback:0,
      remnant:0,
      request_graph:[],
      ecpm_graph:[],
      earning_graph:[],
      chartData: [],
      customersPie: {
        series: [690, 258, 149],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          labels: ['Impressions', 'Passback', 'Remnant'],
          dataLabels: {
            enabled: false,
          },
          legend: { show: false },
          stroke: {
            width: 4,
          },
          colors: [$themeColors.primary, $themeColors.warning, $themeColors.danger],
        },
      },
      revenue: {},
      revenueComparisonLine: {
        series: [
          {
            name: 'This Month',
            data: [45000, 47000, 44800, 47500, 45500, 48000, 46500, 48600],
          },
          {
            name: 'Last Month',
            data: [46000, 48000, 45500, 46600, 44500, 46500, 45000, 47000],
          },
        ],
        chartOptions: {
          chart: {
            toolbar: { show: false },
            zoom: { enabled: false },
            type: 'line',
            offsetX: -10,
          },
          stroke: {
            curve: 'smooth',
            dashArray: [0, 12],
            width: [4, 3],
          },
          legend: {
            show: false,
          },
          colors: ['#d0ccff', '#ebe9f1'],
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              inverseColors: false,
              gradientToColors: [$themeColors.primary, '#ebe9f1'],
              shadeIntensity: 1,
              type: 'horizontal',
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100, 100, 100],
            },
          },
          markers: {
            size: 0,
            hover: {
              size: 5,
            },
          },
          xaxis: {
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '1rem',
              },
            },
            axisTicks: {
              show: false,
            },
            categories: ['01', '05', '09', '13', '17', '21', '26', '31'],
            axisBorder: {
              show: false,
            },
            tickPlacement: 'on',
          },
          yaxis: {
            tickAmount: 5,
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '1rem',
              },
              formatter(val) {
                return val > 999 ? `${(val / 1000).toFixed(0)}k` : val
              },
            },
          },
          grid: {
            borderColor: '#e7eef7',
            padding: {
              top: -20,
              bottom: -10,
              left: 20,
            },
          },
          tooltip: {
            x: { show: false },
          },
        },
      },
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        Current      : 'light-primary',
        Professional : 'light-success',
        Rejected     : 'light-danger',
        Resigned     : 'light-warning',
        Applied      : 'light-info',
      }
      return status => statusColor[status]
    },
  },
  methods:{
    getpageData(queries=null){
      var query = '';
      if(queries){
        query += '?type=' + queries.type;
        query += '&range=' + queries.range;
      }
      const accessToken = useJwt.getToken('accessToken')
      this.$http.get('https://api.purplepatch.online/publisher'+query, {
          headers: {
            Authorization:'Bearer ' + accessToken,
            'x-access-token': accessToken
          }
      })
      .then(res => {
        this.rows = res.data.active_slots_list_object; 
        var sum_stats= res.data.sum_stats;
        var graph_stats= res.data.graph_stats;
        this.active_slots=this.rows.length;
        this.requests= sum_stats.requests;
        this.ecpm= sum_stats.ecpm;
        this.earning= sum_stats.earning;
        this.impressions= sum_stats.wins;
        this.passback= sum_stats.passback;
        this.remnant= parseInt(sum_stats.wins) - parseInt(sum_stats.passback);
        this.request_graph = graph_stats.requests;
        this.ecpm_graph = graph_stats.ecpm;
        this.earning_graph = graph_stats.earning;
      });
    }
  },
  created() {
    // get page data on create route
    this.getpageData();
  },
  watch:{
    "$route.query"(value) {
      this.getpageData(value);
    }
  } 
}
</script>
 
<style lang="scss" >
  @import '@core/scss/vue/libs/publisher.scss';
</style>
